var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('form',{class:_vm.b()},[_c('div',{class:_vm.b('title')},[_c('span',[_vm._v("Услуги и цены")])]),_c('div',{class:_vm.b('list')},_vm._l((_vm.serviceList),function(serviceItem,index){return _c('div',{key:`service-${index}`,class:_vm.b('item')},[_c('FormGroup',{class:_vm.b('group')},[_c('BaseSelectNew',{staticClass:"select-field",attrs:{"label":_vm.isTablet ? '' : 'Услуга*',"placeholder":"Выберите услугу","label-path":"name","track-by":"name","searchable":true,"options":_vm.serviceTypesList,"error":serviceItem.service.$dirty && !serviceItem.service.required
              ? 'Поле не заполнено'
              : null},on:{"input":function($event){return _vm.update(serviceItem.service.$model)},"blur":function($event){return serviceItem.service.$touch()}},model:{value:(serviceItem.service.$model),callback:function ($$v) {_vm.$set(serviceItem.service, "$model", $$v)},expression:"serviceItem.service.$model"}})],1),_c('FormGroup',{class:_vm.b('group', { two: true })},[_c('div',{staticClass:"group-two-wrap"},[_c('BaseSelectNew',{staticClass:"select-field",attrs:{"placeholder":"За услугу","label":_vm.isTablet ? '' : 'Формат цены*',"label-path":"name","track-by":"id","searchable":true,"options":_vm.priceTypes,"error":serviceItem.priceType.$dirty && !serviceItem.priceType.required
                ? 'Поле не заполнено'
                : null},on:{"input":_vm.update,"blur":function($event){return serviceItem.priceType.$touch()}},model:{value:(serviceItem.priceType.$model),callback:function ($$v) {_vm.$set(serviceItem.priceType, "$model", $$v)},expression:"serviceItem.priceType.$model"}}),_c('Field',{attrs:{"placeholder":_vm.isTablet ? 'Цена  (руб.)' : '',"label":_vm.isTablet ? '' : 'Цена  (руб.)',"type":"number","min":"0","step":"1","error-text":serviceItem.price.$dirty && !serviceItem.price.required
                ? 'Поле не заполнено'
                : '',"has-error":serviceItem.price.$dirty && !serviceItem.price.required,"max":"","is-shake-error":true,"group":true},on:{"input":_vm.update,"blur":function($event){return serviceItem.price.$touch()}},model:{value:(serviceItem.price.$model),callback:function ($$v) {_vm.$set(serviceItem.price, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"serviceItem.price.$model"}})],1)])],1)}),0),_c('div',{class:_vm.b('btn-wrap')},[_c('BaseButton',{class:_vm.b('btn'),attrs:{"color":"purple","small":true},on:{"click":_vm.addService},scopedSlots:_vm._u([{key:"icon-left",fn:function(){return [_c('BaseIcon',{attrs:{"icon-name":"plus","fill":'white',"width":18,"height":18}})]},proxy:true}])},[_c('span',[_vm._v("Добавить услугу")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }