
import { Component, Prop } from 'vue-property-decorator'
import { required, numeric } from 'vuelidate/lib/validators'
import { ImplementerServiceModel } from '@/store/modules/implementer.module'
import { ServiceTypesModel } from '@/core/models/ServiceTypesModel'
import { namespace } from 'vuex-class'
const ServiceTypesStore = namespace('ServiceTypesStore')

import FormGroup from '@/components/forms/FormGroup.vue'
import Field from '@/components/forms/Field.vue'
import BaseSelectNew from '@/components/forms/BaseSelectNew/BaseSelect.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'

import { BreakpointsMixin } from '@/mixins/breakpoints.mixin'

const validations = {
  value: {
    required,
    $each: {
      service: {
        required,
      },
      priceType: {
        required,
      },
      price: {
        required,
        numeric,
      },
    },
  },
}

@Component({
  name: 'ImplementerProfileServicesForm',
  components: {
    FormGroup,
    Field,
    BaseSelectNew,
    BaseButton,
    BaseIcon,
  },
  validations,
})
export default class ImplementerProfileServicesForm extends BreakpointsMixin {
  @Prop({ required: true })
  value!: ImplementerServiceModel[]

  priceTypes = [
    {
      name: 'Сдельная',
      icon: '',
      id: 1,
    },
    {
      name: 'Почасовая',
      icon: '',
      id: 2,
    },
  ]

  @ServiceTypesStore.Getter
  public serviceTypesList!: ServiceTypesModel

  get requiredErrorText(): string {
    return 'Поле не заполнено'
  }

  get serviceList(): number {
    return this.$v.value.$each.$iter
  }

  public addService(): void {
    const serviceList: ImplementerServiceModel[] = [...this.value]
    serviceList.push({
      service: '',
      priceType: '',
      price: 0,
    })
    this.$emit('input', serviceList)
  }

  public update(serviceItem: any): void {
    // console.log('update', serviceItem)
    // const serviceList: ImplementerServiceModel[] = [...this.value]
    // serviceList[serviceItem] =
    // this.$emit('input', {
    //   service: this.service,
    //   priceType: this.priceType,
    //   price: this.price,
    // })
  }
}
