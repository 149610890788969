
import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator'
import vClickOutside from 'v-click-outside'

@Component({
  name: 'BaseSelect',
  directives: {
    clickOutside: vClickOutside.directive,
  },
})
export default class BaseSelect extends Vue {
  @Prop({ default: undefined })
  label?: string

  @Prop({ default: undefined })
  id!: string

  @Prop({ default: undefined })
  name?: string

  @Prop({ default: '' })
  placeholder?: string

  /**
   * What will output select when he will change
   * By default he output item array
   */
  @Prop({ default: undefined })
  trackBy?: string

  @Prop({ default: undefined })
  searchBy?: string

  @Prop({ default: undefined })
  labelPath?: string

  @Prop({ required: true })
  value!: any

  @Prop({ required: true, type: [Array, Object] })
  options!: any

  @Prop({ default: false })
  multiple?: boolean

  @Prop({ default: false })
  disabled?: boolean

  @Prop({ default: false })
  searchable?: boolean

  @Prop({ default: '' })
  error?: string

  @Prop({ default: false })
  hasBottomButton?: boolean

  @Prop({ default: false })
  isDisableBottomButton?: boolean

  @Watch('searchVisible')
  onSearchVisibleChanged(visible: boolean): void {
    if (visible) {
      this.$nextTick(() => {
        this.searchInput.focus()
      })
    }
  }

  @Ref() readonly searchInput!: HTMLInputElement

  // data
  isDropdownOpen = false
  searchVisible = false
  searchValue = ''

  // methods
  get proxyValue(): any {
    if (this.trackBy) {
      return this.getValueByTrack(this.trackBy)
    } else if (this.labelPath) {
      return this.getValueByLabelPath(this.labelPath)
    }

    return this.value
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  set proxyValue(next: any) {
    const set = new Set()

    if (this.multiple) {
      this.value.forEach((item: any) => {
        set.add(item)
      })

      if (set.has(next)) {
        set.delete(next)
      } else {
        set.add(next)
      }
    }

    const emitValue = this.multiple ? Array.from(set) : next

    this.$emit('input', emitValue)
  }

  get displayValue(): string {
    if (this.multiple) {
      return this.proxyValue.join(', ')
    } else {
      return this.proxyValue
    }
  }

  get list(): any {
    let list: any = this.options

    if (this.searchValue) {
      const reg = new RegExp(this.searchValue.toLowerCase().replace('ё', 'е'))
      const prop = this.searchBy || this.labelPath

      list = this.options?.filter((option: any) => {
        if (prop) {
          return (option[prop] as string)
            .toString()
            .toLowerCase()
            .replace('ё', 'е')
            .match(reg)
        }

        return (option as string)
          .toString()
          .toLowerCase()
          .replace('ё', 'е')
          .match(reg)
      })
    }

    return list
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public isSelected(value: any): boolean {
    let hasValue: boolean

    if (this.multiple) {
      if (this.trackBy) {
        hasValue = (this.proxyValue as any)?.some((proxyItem: any) => {
          const item =
            this.labelPath && typeof proxyItem === 'object'
              ? proxyItem[this.labelPath]
              : proxyItem

          return item === value[this.labelPath ?? this.trackBy!]
        })
      } else {
        hasValue = (this.proxyValue as any)?.some((proxyItem: any) => {
          const item =
            this.labelPath && typeof proxyItem === 'object'
              ? proxyItem[this.labelPath]
              : proxyItem

          const compairableValue =
            this.labelPath && typeof value === 'object'
              ? value[this.labelPath]
              : value

          return item === compairableValue
        })
      }
    } else {
      if (this.trackBy) {
        hasValue =
          (this.labelPath &&
          this.proxyValue &&
          typeof this.proxyValue === 'object'
            ? this.proxyValue[this.labelPath]
            : this.proxyValue) ===
          (this.labelPath && value && typeof value === 'object'
            ? value[this.labelPath]
            : value)
      } else {
        hasValue = this.proxyValue === value
      }
    }

    return hasValue
  }

  public open(): void {
    if (!this.disabled) {
      this.isDropdownOpen = true
      if (this.searchable) {
        this.searchVisible = true
      }
    }
  }

  public hide(): void {
    this.isDropdownOpen = false
    this.searchValue = ''
    this.searchVisible = false
    this.$emit('blur')
  }

  public itemClickHandler(item: never): void {
    this.proxyValue = this.trackBy ? item[this.trackBy] : item

    if (!this.multiple) {
      this.hide()
    } else {
      if (this.searchInput) this.searchInput.focus()
    }
  }

  // some computed values.
  get hasValue(): boolean {
    if (this.multiple) {
      return !!(this.proxyValue && this.proxyValue.length > 0)
    }

    return !!this.proxyValue
  }

  getValueByTrack(track: string): string[] | string {
    if (Array.isArray(this.value)) {
      return this.options
        .filter((option: any) =>
          this.value?.some((value: string) => option[track] === value)
        )
        .map((item: any) => item[this.labelPath ?? track])
    }

    const option = this.options.find(
      (option: any) => option[track ?? this.labelPath] === this.value
    )

    if (option) {
      return option[this.labelPath ?? track]
    }

    return this.value
  }

  getValueByLabelPath(labelPath: string): string[] | string {
    if (Array.isArray(this.value)) {
      return this.options
        .filter((option: any) =>
          this.value?.some(
            (value: string) =>
              option[labelPath as any] === value[labelPath as any]
          )
        )
        .map((item: any) => item[labelPath])
    }

    const option = this.options.find(
      (option: any) => option[labelPath] === this.value
    )

    if (option) {
      return option[labelPath]
    }

    return this.value
  }

  onBottomButtonClick(): void {
    if (this.isDisableBottomButton) {
      return
    }
    this.$emit('click-button')
  }
}
