var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"root",staticClass:"select",class:{ active: _vm.isDropdownOpen, selected: _vm.hasValue, error: _vm.error }},[(_vm.label)?_c('label',{staticClass:"select__label",attrs:{"for":_vm.id},on:{"click":_vm.open}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{staticClass:"select__wrapper"},[_c('div',{staticClass:"select__input",on:{"click":_vm.open}},[(_vm.searchVisible)?_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.searchValue),expression:"searchValue",modifiers:{"trim":true}}],ref:"searchInput",attrs:{"type":"text","spellcheck":"false"},domProps:{"value":(_vm.searchValue)},on:{"input":function($event){if($event.target.composing)return;_vm.searchValue=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}):_c('input',{attrs:{"id":_vm.id,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"type":"text","readonly":""},domProps:{"value":_vm.displayValue}}),(_vm.$slots.suffix)?_c('div',{staticClass:"select__suffix"},[_vm._t("suffix")],2):_vm._e(),(_vm.error)?_c('div',{staticClass:"error"},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('div',{staticClass:"select__arrow"},[_c('svg',{attrs:{"width":"11","height":"10","viewBox":"0 0 11 10","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M9.5293 4.11731L5.52234 8.12427L1.51538 4.11731","stroke":"#16192C","stroke-width":"1.5","stroke-linecap":"round"}})])])]),_c('transition',{attrs:{"name":"fade"}},[(_vm.isDropdownOpen)?_c('ul',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hide),expression:"hide"}],staticClass:"select__dropdown"},[(_vm.list && _vm.list.length > 0)?_vm._l((_vm.list),function(item){return _c('li',{key:JSON.stringify(_vm.trackBy ? item[_vm.trackBy] : item),class:{
              selected: _vm.isSelected(item),
              disabled: item.disabled,
            },on:{"click":function($event){item.disabled || _vm.itemClickHandler(item)}}},[(_vm.$scopedSlots.item)?[_c('div',{staticClass:"select__item"},[_vm._t("item",null,{"item":item})],2)]:[_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:(
                  item.tooltip
                    ? { boundary: 'window', placement: 'right' }
                    : { trigger: 'manual' }
                ),expression:"\n                  item.tooltip\n                    ? { boundary: 'window', placement: 'right' }\n                    : { trigger: 'manual' }\n                "}],staticClass:"select__item",attrs:{"content":item.tooltip}},[_vm._v(" "+_vm._s(_vm.labelPath ? item[_vm.labelPath] : item)+" ")])]],2)}):[_c('li',{staticClass:"empty",on:{"click":function($event){if (_vm.searchInput) _vm.searchInput.focus()}}},[_vm._v(" Ничего не найдено... ")])],(_vm.hasBottomButton)?[(_vm.isDisableBottomButton)?_c('span',{directives:[{name:"tippy",rawName:"v-tippy"}],attrs:{"content":"На текущем тарифе достигнуто максимальное количество адресов"}},[_c('li',{staticClass:"select__item select__item--button",class:{ disabled: _vm.isDisableBottomButton },on:{"click":_vm.onBottomButtonClick}},[_vm._t("buttons",function(){return [_vm._v("Добавить")]})],2)]):_c('li',{staticClass:"select__item select__item--button",class:{ disabled: _vm.isDisableBottomButton },on:{"click":_vm.onBottomButtonClick}},[_vm._t("buttons",function(){return [_vm._v("Добавить")]})],2)]:_vm._e()],2):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }